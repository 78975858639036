body{
    counter-reset: section; 
}
.first-column{
    position: relative;
}

.first-column p::before {
    counter-increment: section;
    content: " " counter(section) ": ";
    position: absolute;
    left: -0.5em;
}
.second-column{
    counter-reset: section2;
    position: relative;
}

.second-column p::before {
    counter-increment: section2;
    content: " " counter(section2) ": ";
    position: absolute;
    left: -0.5em;
}

.third-column{
    counter-reset: section3;
    position: relative;
}

.third-column p::before {
    counter-increment: section3;
    content: " " counter(section3) ": ";
    position: absolute;
    left: -0.5em;
}

.fourth-column{
    counter-reset: section4;
    position: relative;
}

.fourth-column p::before {
    counter-increment: section4;
    content: " " counter(section4) ": ";
    position: absolute;
    left: -0.5em;
}



@media screen and (max-width: 599px) {
    .Clients-logos{
        font-family: Poppins;
        font-weight: bold;
        font-size: 18px;
    }
    .Clients-logos h1{
        font-family: Poppins;
        font-size: 25px;
        font-weight: bold;
    }
    .client-images img{
        width: 13%;
        margin: 20px;
    }
    .col-md-3 p,.col-md-3 h3{
        text-align: start;
    }
  
    .client-list h1{
        font-family: Poppins;
        font-weight: bold;
        font-size: 25px;
        margin-bottom: 5%;
        margin-top: 5%;
    }
    
}

@media screen and (max-width: 375px) {
    .card7{
        width: 150px;
        height: 150px;
        max-width: 200px;
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 10px;
        margin: 10px;
    }
}


@media screen and (min-width: 600px) {
    .Clients-logos{
        font-family: Poppins;
        font-weight: bold;
        font-size: 18px;
    }
    .Clients-logos h1{
        font-family: Poppins;
        font-size: 25px;
        font-weight: bold;
    }

    .client-images img{
        width: 13%;
        margin: 20px;
    }
    .col-md-3 p,.col-md-3 h3{
        text-align: start;
    }
    .col-md-3 h3{
        font-size: 18px;
    }
    .client-rows{
        width: 95%;
        margin: 0 auto;
    }
  
    .client-list h1{
        font-family: Poppins;
        font-weight: bold;
        font-size: 25px;
        margin-bottom: 5%;
        margin-top: 5%;
    }

    .col-md-3 {
        position: relative;
    }

    .client2,.client15{
        width: 10% !important;
    }


    
}

@media screen and (min-width: 900px) {
    .client-images{
        width: 90%;
        margin: 0 auto;
    }
    .client-images img{
        width: 10%;
        margin: 30px;
    }

}

@media screen and (min-width: 1200px) {
    .client-rows{
        width: 80%;
        margin: 0 auto;
    }
    .client2,.client15{
        width: 7% !important;
    }
}
