@media screen and (max-width: 599px) {
    .top-image{
        width: 100%;
        position: absolute;
        z-index: -1 !important;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
    .bottom-image{
        width: 100%;
        position: absolute;
        z-index: -1 !important;
        left: 0;
        right: 0;
        margin: 0 auto;
        margin-top: -20%;
       
    }
    .first-part{
        width: 100%;
    }
    .first-aprt-content,.first-aprt-content2,.first-aprt-content3,.first-aprt-content4,.first-aprt-content5{
        width: 85%;
        height: 250px;
        margin: 0 auto;
        margin-bottom: 5%;

    }
    .fs-part{
        margin-top: 15% !important;
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        width: 80%;
    }
    .right-image img{
        width: 100%;
        margin-left: 25%;
        margin-top: -30%;

    }    
    .left-content{
        float: left;
        width: 64%;
        margin-left: -5%;
    }
    .left-content-text{
        margin-left: 20% !important;
    }
    .left-content-text h1{
        font-family: Poppins;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        text-align: start;
        margin-left: 5% !important;
    }
    .left-content p{
        font-family: Poppins;
        font-size: 12px;
        line-height: 15px;
        text-align: start;
        margin-left: 10px;
    }
    .right-image{
        float: right;
        width: 40%;
    }
    .left-btn{
        font-family: Poppins;
        font-size: 10px;
        width: 6rem;
        border-radius: 50px;
        float: left;
        background: #64B3F4;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
        border-radius: 50px;
        font-weight: 600;
        letter-spacing: 0.05em;
        margin-left: 3rem;
    }
    .no1{
        float: left;
        width: 12%;
        margin-left: 0.5rem;
        margin-top: 15px;
    }
    .left-image img{
        width: 100%;
        margin-top: -30%;
        margin-left: -50%;
    }    
    .right-content{
        float: right;
        width: 64%;
        margin-left: -5%;
    }
    .right-content h1{
        font-family: Poppins;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        text-align: start;
        margin-left: -10%;
    }
    .right-content p{
        font-family: Poppins;
        font-size: 13px;
        line-height: 15px;
        text-align: start;
        margin-left: -10%;
    }
    .left-image{
        float: left;
        width: 40%;
    }
    .right-btn{
        font-family: Poppins;
        font-size: 12px;
        width: 6rem;
        border-radius: 50px;
        float: left;
        background: #64B3F4;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
        border-radius: 50px;
        font-weight: 600;
        letter-spacing: 0.05em;
        margin-left: -10%;
        font-size: 10px;
    }
    .no2{
        float: right;
        width: 12%;
        margin-right: -0.9rem;
        margin-top: 15px;
    }
    .client-header{
        font-family: Poppins;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 30px;
    }

    .Clients-sections{
        margin-top: 0%;
    }
    .wrapper{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }
    .slider1 {
        width: 600px;
        height: 80px;
        position: relative;
        overflow: hidden;
      }
     .slide1 {
         height: 50px;
         display: flex;
         align-items: center;
         animation: slideshow 8s linear infinite;
     } 
     .slide1 img {
         height: 50px;
         padding: 0 30px 0 30px;
     }

     @keyframes slideshow {
         0% {transform: translateX(0);}
         100% {transform: translateX(-100%);}
     }
}

@media screen and (max-width: 375px) {

}



@media screen and (min-width: 600px) {
    .angle-vector{
        width: 70%;
        position: relative;
        z-index: -1;
        margin-top: -55%;
        margin-bottom: -55%;
    }
    .top-image{
        width: 100%;
        position: absolute;
        z-index: -1 !important;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
    .bottom-image{
        width: 100%;
        position: absolute;
        z-index: -1 !important;
        left: 0;
        right: 0;
        margin: 0 auto;
        margin-top: -10%;  
    }
    .first-part{
        width: 100%;
    }
    .first-aprt-content,.first-aprt-content2,.first-aprt-content3,.first-aprt-content4,.first-aprt-content5{
        width: 85%;
        height: 250px;
        margin: 0 auto;
        margin-bottom: 0%;

    }
    .fs-part{
        margin-top: 15% !important;
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        width: 80%;
    }
    .right-image img{
        width: 100%;
        margin-left: 25%;
        margin-top: -30%;

    }    
    .left-content{
        float: left;
        width: 64%;
        margin-left: -5%;
    }
    .left-content-text{
        margin-left: 20% !important;
    }
    .left-content-text h1{
        font-family: Poppins;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        text-align: start;
        margin-left: 3% !important;
    }
    .left-content p{
        font-family: Poppins;
        font-size: 12px;
        line-height: 15px;
        text-align: start;
        margin-left: 10px;
    }
    .right-image{
        float: right;
        width: 40%;
    }
    .left-btn{
        font-family: Poppins;
        font-size: 12px;
        width: 7rem;
        border-radius: 50px;
        float: left;
        background: #64B3F4;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
        border-radius: 50px;
        font-weight: 600;
        letter-spacing: 0.05em;
        margin-left: 0.5rem;
    }
    .no1{
        float: left;
        width: 12%;
        margin-left: 0.5rem;
        margin-top: 15px;
    }
    .left-image img{
        width: 100%;
        margin-top: -30%;
        margin-left: -50%;
    }    
    .right-content{
        float: right;
        width: 64%;
        margin-left: -5%;
    }
    .right-content h1{
        font-family: Poppins;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        text-align: start;
        margin-left: -10%;
    }
    .right-content p{
        font-family: Poppins;
        font-size: 13px;
        line-height: 25px;
        text-align: start;
        margin-left: -10%;
    }
    .left-image{
        float: left;
        width: 40%;
    }
    .right-btn{
        font-family: Poppins;
        font-size: 12px;
        width: 7rem;
        border-radius: 50px;
        float: left;
        background: #64B3F4;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
        border-radius: 50px;
        font-weight: 600;
        letter-spacing: 0.05em;
        margin-left: -10%;
        cursor: pointer;
        position: relative;
        z-index: 9999;
    }
    .no2{
        float: right;
        width: 12%;
        margin-right: -0.9rem;
        margin-top: 15px;
    }
    .client-header{
        font-family: Poppins;
        font-size: 20px;
        font-weight: bold;
    }

    .Clients-sections{
        margin-top: 5%;
    }
    .wrapper{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }
    .slider1 {
        width: 600px;
        height: 150px;
        position: relative;
        overflow: hidden;
      }
     .slide1 {
         height: 150px;
         display: flex;
         align-items: center;
         animation: slideshow 8s linear infinite;
     } 
     .slide1 img {
         height: 80px;
         padding: 0 30px 0 30px;
     }

     @keyframes slideshow {
         0% {transform: translateX(0);}
         100% {transform: translateX(-100%);}
     }
}

@media screen and (min-width: 768px) {
    .Clients-sections{
        margin-top: 12%;
    }
}

@media screen and (min-width: 900px) {
    .Clients-sections{
        left: 0;
        right: 0;
        margin: 0 auto;
        margin-top: 20% !important;
    }
}


@media screen and (min-width: 1200px) {
    .first-aprt-content,.first-aprt-content2,.first-aprt-content3,.first-aprt-content4,.first-aprt-content5{
        width: 85%;
        height: 450px;
        margin: 0 auto;
        margin-bottom: 0%;
    }
    .left-content h1,.right-content h1{
        font-size: 30px;
    }
    .left-content p,.right-content p{
        font-size: 20px;
        line-height: 40px; 
    }
    .client-section h1{
        font-family: Poppins;
        font-size: 30px;
    }
    .wrapper{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }
    .slider1 {
        width: 1300px;
        height: 150px;
        position: relative;
        overflow: hidden;
    }
    .left-content-text h1{
        margin-left: 2% !important;
    }

    .client-header{
        font-size: 30px;
    }
  
    .slider1 {
        width: 80%;
        height: 200px;
        position: relative;
        overflow: hidden;
      }
     .slide1 {
         height: 150px;
         display: flex;
         align-items: center;
         animation: slideshow 8s linear infinite;
     } 
     .slide1 img {
         width: 25%;
         HEIGHT: 60%;
         padding: 0 30px 0 30px;
     }
     .bottom-image{
        margin-top: -10% !important;  
    }


}

@media screen and (min-width: 1800px) {
    .left-content-text h1{
        margin-left: 1% !important;
    }
    .first-aprt-content,.first-aprt-content2,.first-aprt-content3,.first-aprt-content4,.first-aprt-content5{
        width: 85%;
        height: 450px;
        margin: 0 auto;
        margin-bottom: 10%;
    }
    .Clients-sections{
        left: 0;
        right: 0;
        margin: 0 auto;
        margin-top: 30% !important;
    }
    .slider1 {
        width: 80%;
        height: 150px;
        position: relative;
        overflow: hidden;
      }
     .slide1 {
         height: 150px;
         display: flex;
         align-items: center;
         animation: slideshow 8s linear infinite;
     } 
     .slide1 img {
         height: 110px;
         padding: 0 0px 0 15px;
     }
}