.prof-text{
     position: relative;
    }   

.prof-text p::before {
        content: '■';
        position: absolute;
        left: -1em;          /* Adjust this value so that it appears where you want. */
        font-size: 0.8em;      /* Adjust this value so that it appears what size you want. */
    }
    
 

@media screen and (max-width: 599px) {
    .Promotors-section h1,.Associates-section h1{
        font-family: Poppins;
        font-weight: bold;
        color: black;
        font-size: 30px;
        margin-top: 4%;
    }

    .promoter1 img{
        width: 40%;
        margin: 20px;
    }
    .card-text{
        font-family: Poppins;
        font-weight: normal;
        text-align: start;
    }
    .promoter1 h3{
        font-family: Poppins;
        font-weight: bold;
        margin-top: 0px;
        font-size: 15px;
    }

    .Associates-section{
        width: 90%;
        margin: 0 auto;
    }
    .card{
        background: #FFFFFF;
        /* Card Shadow */

        box-shadow: 0px 0px 50px rgba(72, 106, 71, 0.4);
        border-radius: 40px;
        margin-top: 5% !important;
        width: 90%;
        margin: 0 auto;
    }

    .card6{
        width: 80%;
        box-shadow: 0px 0px 50px rgba(72, 106, 71, 0.4);
        border-radius: 10px;
        padding: 5px;
        display: inline-block;
        margin: 10px;
    }
    .card6 h1{
        font-size: 20px;
    }
    .card6 p{
        font-size: 15px;
    }
  
}




@media screen and (min-width: 600px) {
    .Promotors-section h1,.Associates-section h1{
        font-family: Poppins;
        font-weight: bold;
        color: black;
        font-size: 30px;
        margin-top: 4%;
    }
    .card-img{
        width: 30%;
    }
    .card-title{
        font-family: Poppins;
        font-weight: bold;
    }
    .card-text{
        font-family: Poppins;
        font-weight: normal;
        text-align: justify;
    }
    .promoter1{
        width: 80%;
        margin: 0 auto;
    }
    .card{
        background: #FFFFFF;
        /* Card Shadow */

        box-shadow: 0px 0px 50px rgba(72, 106, 71, 0.4);
        border-radius: 40px;
        margin-top: 5% !important;
    }
    .Associates-section{
        width: 90%;
        margin: 0 auto;
        padding: 10px;
    }
    .associates-cards{
        display: grid;
        grid-template-columns: repeat(2,0.5fr);
        grid-gap: 8px;
        justify-content: center;
        align-content: center;
        margin-top: 50px;
    }
   
    .card6{
        width: 300px;
        height: 150px;
        max-height: 150px;
        box-shadow: 0px 0px 50px rgba(72, 106, 71, 0.4);
        border-radius: 10px;
        padding: 10px;
    }
    .card6 h1{
        font-size: 23px;
        
    } 
    .status{
        line-height: 30px;
    }
    .web-link{
        margin-top: -12px;
    }
  
}

@media screen and (min-width: 768px) {
    .card-img{
        width: 70%;
        margin-top: 20px;
    }
  
    .associates-cards{
        display: grid;
        grid-template-columns: repeat(2,0.2fr);
        grid-gap: 40px;
        justify-content: center;
        align-content: center;
        margin-top: 50px;
    }
  
}

@media screen and (min-width: 900px) {

    .Associates-section{
        width: 90%;
        margin: 0 auto;
        margin-top: 10%;
        margin-bottom: -5%;
    }
    .card-img1{
        width: 50%;
    }
    .card-img{
        margin-top: 0px;
    }
    .card{
        padding: 15px;
    }
    .associates-cards{
        display: grid;
        grid-template-columns: repeat(3,0.8fr);
        grid-gap: 10px;
        justify-content: center;
        align-content: center;
        margin-top: 50px;
    }
    .card-title{
        font-size: 15px;
    }
    .card-body2{
        margin-top: 30px;
    }
}

@media screen and (min-width: 1200px) {
    .associates-cards{
        grid-template-columns: repeat(3,0.1fr);
        grid-gap: 25px;
    }
    .Associates-section{
        width: 90%;
        margin: 0 auto;
        margin-top: 15%;
        margin-bottom: -5%;
    }
    .card-body1{
        padding: 50px;
    }
    .card-body2{
        margin-top: 45px;
    }
    .card-body3{
        margin-top: 35px;
    }
 

}

@media screen and (min-width: 1400px) {
    .associates-cards{
        grid-template-columns: repeat(4,0.1fr);
        grid-gap: 25px;
    }
    .card{
        max-width: 1400px;
        margin: 0 auto;
    }
    .card-body2{
        margin-top:60px;
    }
    .card-body3{
        margin-top: 45px;
    }
   
}

@media screen and (min-width: 1800px) {
    .associates-cards{
        grid-template-columns: repeat(5,0.1fr);
    }
    .card-body1{
        margin-top: 35px;
    }

}
