html, body{
    margin: 0px;
    padding: 0;
    height: 100%;
  }
body{
    overflow-x: hidden !important;
}
html {
  scroll-behavior: smooth;
}
#site-wrapper{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#page-content{
  flex: 1 0 auto;
}

@media screen and (max-width: 599px) {
    .main-title{
        font-family: Dancing Script;
        font-size: 18px;
        font-style: normal;
        line-height: 26px;
        letter-spacing: 0.02em;
        color: #000000;
        font-weight: bold;
    }
    
     .Scrolling-text{
         font-family: 'Handlee', cursive;
        margin-top: 30px;
        font-size: 18px;
    }
    .whowe-are-section{
        padding: 1rem;
        width: 100%;
        height: 500px;
        margin: 0 auto;
        margin-bottom: 15%;
    }
 
   
    .leftside-text h1{
        font-family: Poppins;
        font-size: 15px;
        line-height: 20px;
        font-weight: bold;
        color:"#000000";
        text-align: start;
    }
    .leftside-text p{
        font-family: Poppins;
        font-size: 12px;
        text-align: justify;
        letter-spacing: 0.05em;
        color: black;
        margin-top: 20px;
    }
    .leftside-text{
        padding: 2rem;
    }
  
    .whowe-are-image{
       width: 350px;
       height: 150px;
        margin: 0 auto;
    }
    .read-more-btn{
        background: #64B3F4;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
        border-radius: 50px;
        font-size: 13px;
        letter-spacing: 0.05em;
        float: left;
    }
    .caption{
        font-size: 15px;
        margin-left: 65%;
    }

    /* this is over-view section */
    .overview-section{
        width: 90%;
        height: 300px;
        margin: 0 auto;
        margin-bottom: 0%;
    }
    .overview-cards{
        width: 100%;
        margin: 0 auto;
    }
    .card1{
        width: 30%;
        height: 150px;
        background: #FFFFFF;
        box-shadow: 0px 0px 50px rgba(72, 106, 71, 0.4);
        border-radius: 40px;
        margin: 1.65%;
        float: left;
    }
    .card1 img{
        margin-top: 15px;
        width: 25%;
    }
    .overview-text{
        font-family: Poppins;
        font-weight: bold;
        font-size: 13px;
        line-height: 27px;
        letter-spacing: 0.05em;
        color: black;
        margin-top: 20px;
    }
    .overview-text2{
        margin-top: 40px;
    }
 
}

@media screen and (max-width: 375px) {
    .whowe-are-image{
        width: 250px;
        height: 120px;
    }
}

@media screen and (min-width: 600px) {
    .main-title{
    font-family: Dancing Script;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #000000;
    }
      .Scrolling-text{
        font-family: 'Handlee', cursive;
        margin-top: 30px;
        font-size: 20px;
        color: black;
        font-weight: bold;
        letter-spacing: 2.5px;
    }
    .whowe-are-section{
        padding: 2rem;
        width: 90%;
        height: 300px;
        margin: 0 auto;
        margin-bottom: 10%;
    }
 
    .leftside-text{
        float: left;
        width: 50%;
   
    }
    .leftside-text h1{
        font-family: Poppins;
        font-size: 15px;
        line-height: 20px;
        font-weight: bold;
        color:"#000000";
        text-align: start;
    }
    .leftside-text p{
        font-family: Poppins;
        font-size: 12px;
        text-align: justify;
        letter-spacing: 0.05em;
        color: black;
        margin-top: 20px;
 
    }
    .rightside-image{
        float: right;
        width: 50%;
    }
    .whowe-are-image{
       width: 220px;
       height: 120px;
       margin-left: 10px;
    }
    .read-more-btn{
        background: #64B3F4;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
        border-radius: 50px;
        font-size: 13px;
        letter-spacing: 0.05em;
        float: left;
    }

    .carousel-inner{
        width: 250px;
        height: 150px;
    }
    .caption{
        text-align: end;
        font-size: 18px;
        margin-top: -10px;
        margin-right: 9%;
    }


    /* this is over-view section */
    .overview-section{
        width: 90%;
        height: 250px;
        margin: 0 auto;
        margin-bottom: 5%;

    }
    .overview-cards{
        width: 100%;
        margin: 0 auto;
    }
    .card1{
        width: 30%;
        height: 160px;
        background: #FFFFFF;
        box-shadow: 0px 0px 50px rgba(72, 106, 71, 0.4);
        border-radius: 40px;
        margin: 1.65%;
        float: left;
    }
    .card1 img{
        margin-top: 15px;
        width: 25%;
    }
    .overview-text{
        font-family: Poppins;
        font-weight: bold;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.05em;
        color: black;
        margin-top: 20px;
    }
    .overview-text2{
        margin-top: 40px;
    }
}

@media screen and (min-width: 768px) {
    .carousel-inner{
        width: 290px;
        height: 150px;
    }
    .whowe-are-image{
        width: 270px;
        height: 150px;
        margin-left: 10px;
    }
    .caption{
        margin-top: 0px;
    }
}

@media screen and (min-width: 900px) {
    .whowe-are-section{
        height: 380px;
    }
    .leftside-text h1{
        font-size: 20px;
    }
    .leftside-text p{
        font-size: 15px;
        line-height: 27px;
    }
    .carousel-inner{
        width: 400px;
        height: 250px;
        margin-left: 30px;
    }
    .whowe-are-image{
        width: 98%;
        height: 220px;
        margin-left: 0px;
    }
    .card1{
        width: 30%;
        height: 190px;
    }
    .overview-cards{
        width: 100%;
    }
}

@media screen and (min-width: 1024px) {
    .main-title{
        position: absolute;
        left: 0;
        right: 0;
        margin:  0 auto;
        margin-top: -8%;
        margin-left: 15%;
    }
    .caption{
        margin-top: -40px;
        position: absolute;
        margin-left: 80%;
    }
}

@media screen and (min-width: 1200px) {
    .main-title{
        font-size: 30px;
        margin-left: 25%;
    }
    .caption{
        margin-top: -50px;
        margin-left: 82%;
    }
    .whowe-are-section{
        width: 85%;
        height: 400px;
        max-width: 1300px;
        margin-top: 5%;
    }
    .leftside-text h1{
        font-size: 25px;
    }
    .leftside-text p{
        font-size: 20px;
        line-height: 27px;
    }
    .carousel-inner{
        width: 450px;
        height: 250px;
        margin-left: 20%;

    }
    .whowe-are-image{
        width: 98%;
        height: 250px;
        margin-left: 0px;
    }
    .overview-section{
        width: 85%;
        max-width: 1300px;
    }
    .card1{
        width: 30%;
        height: 210px;
    }
      .Scrolling-text{
        font-size: 28px;
    }
   
}

@media screen and (min-width: 1400px) {
    .caption{
        margin-top: -80px;
    }
}


@media screen and (min-width: 1800px) {
    .main-title{
       margin-top: -3%;
       margin-left: 10%;
       font-size: 35px;
    }
    .whowe-are-section{
        margin-top: 3%;
    }
    .caption{
        margin-top: -20px;
        margin-left: 75%;
    }
}
