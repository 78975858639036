@media screen and (max-width: 599px) {
    .row{
        margin-left: 0px;
    }
    footer{
        margin-top: 20% !important;
        bottom: 0;
        width: 50%;
        margin: 0 auto;
    }
    .footer-left-vector{
        width: 200px;
    }
    .footervector{
        width: 100%;
    
    }
    .footer-links{
        width: 210px;
    }
    .address{
        width: 300px;
    }
    .footer-links p{
        font-size: 11px !important;
    }
 

    .footer-links p{
        text-align: start;
        font-family: Poppins;
        font-size: 12px;
        margin-top: 20px;
    }
    .address{
        width: 200px;
    }
    .address p{
        text-align: start;
        font-size: 12px;
    }
    .phone3{
        margin-left: 40px;
    }
}


@media screen and (min-width: 600px) {
    .row{
        margin-left: 0px;
    }
    footer{
        margin-top: 20%;
        bottom: 0;
        width: 100%;
    }
    .footer-left-vector{
        width: 200px;
    }
    .footervector{
        width: 100%;
        float: left;
    }
    .footer-links{
        width: 210px;
    }
    .address{
        width: 300px;
    }
    .footer-links li{
        text-align: start;
        list-style: none;
    }
    .footer-links li a{
        font-family: Poppins;
        font-size: 12px;
    }

    .footer-links p{
        text-align: start;
        font-family: Poppins;
        font-size: 12px;
        margin-top: 20px;
    }
    .address{
        width: 200px;
    }
    .address p{
        text-align: start;
        font-size: 12px;
    }
    .phone3{
        margin-left: 19%;
    }
}

@media screen and (min-width: 900px) {
    .footer-links li a{
        font-family: Poppins;
        font-size: 14px;
    }
    .footer-links p{
        font-size: 14px;
        margin-left: 10%;
    }
    .links{
        margin-left: 10%;
        margin-top: 10%;
    }
    .address p{
        font-size: 14px;
    }
}

@media screen and (min-width: 900px) {
    .phone3{
        margin-left: 18%;
    }
}

@media screen and (min-width: 1200px) {
    footer{
        margin-top: 15%;
    }
    .footer-links li a{
        font-size: 18px;
    }
    .footer-links p{
        font-size: 18px;
    }
    .address p{
        font-size: 18px;
    }
    .address-content{
        margin-top: 4%;
    }
    .phone3{
        margin-left: 65px;
    }
   
}



@media screen and (min-width: 1800px) {
    footer{
        margin-top: 20%;
    }
 
}