@media screen and (max-width: 599px) {
    .services-cards{
        width: 100%;
    }
    .card2{
        width: 17rem;
        height: 450px;
        background: #FFFFFF;
        box-shadow: 0px 0px 50px rgba(72, 106, 71, 0.4);
        border-radius: 40px;
        margin: 10px;
        display: inline-block;
    }
    .card-img-top{
        padding: 20px;
        width: 50%;
        margin-top: 10px;
    }
    .card-title1{
        font-family: Poppins;
        font-size: 18px;
        font-weight: bold;
    }
    .card-text1{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: justify;
        letter-spacing: 0.02em;
        color: #000000;
    }
    .cards{
        width: 98%;
        margin: 0 auto;
    
    }
    .Workflow-process h1{
        margin-top: 50px;
        font-family: Poppins;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 5%;
    }
    .workflow-image{
        width: 90%;
    }
  
    .card3{
        width: 99%;
        height: 230px;
        background: #FFFFFF;
        box-shadow: 0px 0px 100px rgba(72, 106, 71, 0.5);
        border-radius: 50px;
        margin: 0 auto;
        margin-bottom: 3rem;
        margin-top: 3rem;
    }
    .wl-image{
        float: left;
        width: 40%;
        padding: 35px;
        margin-left: 0px;
    }
    .wl-image img{
        margin-left: -30%;
    }
    .wr-text{
        float: right;
        width: 60%;
        padding: 10px;
        margin-top: 15px;
    }
    
    .wl-image h1{
        font-family: Poppins;
        font-size: 13px;
        font-weight: bold;
        margin-top: 10px;
        margin-left: -25%;
    }
    .wr-text p{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 17px;
        text-align: justify;
        letter-spacing: 0.03em;
        color: #000000;
        margin-left: -12%;
    }

    .wr-image{
        float: right;
        width: 40%;
        padding: 35px;
    }
    .wr-image img{
        margin-left: 20%;
    }
    .wl-text{
        float: left;
        width: 60%;
        padding: 10px;
        margin-top: 30px;
    }
    
    .wr-image h1{
        font-family: Poppins;
        font-size: 13px;
        font-weight: bold;
        margin-top: 10px;
        margin-left: 25%;
    }
    .wl-text p{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 17px;
        text-align: justify;
        letter-spacing: 0.03em;
        color: #000000;
        margin-left: 12%;
    }
    .Facility-eng{
        width: 50%;
        margin: 0 auto;
    }
    .Facility-eng h1{
        font-size: 18px;
        font-weight: bold;
        font-family: Poppins;
        margin-bottom: 10%;
        line-height: 30px;
    }
    .Facility-eng p{
        text-align: start;
        margin-top: 0px;
    }
    .right{
        margin-left: 18%;
    }
    .left{
        margin-top: 3%;
        margin-left: 18%;
    } 
    .left{
        list-style-type: none;
        position: relative;    /* It's needed for setting position to absolute in the next rule. */
        
    }

    .left p::before{
        content: '■';
        position: absolute;
        left: -1.2em;          /* Adjust this value so that it appears where you want. */
        font-size: 1.1em;      /* Adjust this value so that it appears what size you want. */
    } 

    .right{
        list-style-type: none;
        position: relative;    /* It's needed for setting position to absolute in the next rule. */
       
    }
    .right p::before{
        content: '■';
        position: absolute;
        left: -1.2em;          /* Adjust this value so that it appears where you want. */
        font-size: 1.1em;      /* Adjust this value so that it appears what size you want. */
    }

}



@media screen and (min-width: 600px) {
    .services-cards{
        width: 100%;
        margin: 0 auto;
    }
 
  
    .card2{
        width: 17rem;
        height: 460px;
        max-height: 460px;
        background: #FFFFFF;
        box-shadow: 0px 0px 50px rgba(72, 106, 71, 0.4);
        border-radius: 40px;
        margin: 10px;
        padding: 0px;
    }
    .card-img-top{
        padding: 5px;
        width: 70%;
        margin-top: 0px;
    }
    .card-title1{
        font-family: Poppins;
        font-size: 18px;
        font-weight: bold;
        margin-top: -40px;
    }
    .card-text1{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: justify;
        letter-spacing: 0.02em;
        color: #000000;
    }
    .cards{
        display: grid;
        grid-template-columns: repeat(2,0.1fr);
        grid-gap: 0px;
        padding: 50px;
        justify-content: center;
        align-content: center;
    }
 
   .Workflow-process{
        margin-bottom: 15% !important;
   }

    .Workflow-process h1{
        margin-top: 50px;
        font-family: Poppins;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 5%;
    }
    .workflow-image{
        width: 90%;
    }
  
    .card3{
        width: 80%;
        height: 200px;
        background: #FFFFFF;
        box-shadow: 0px 0px 100px rgba(72, 106, 71, 0.5);
        border-radius: 50px;
        margin: 0 auto;
        margin-bottom: 3rem;
        margin-top: 3rem;
    }
    .wl-image{
        float: left;
        width: 40%;
        padding: 35px;
    }
    .wl-image img{
        margin-left: -30%;
    }
    .wr-text{
        float: right;
        width: 60%;
        padding: 20px;
    }
    
    .wl-image h1{
        font-family: Poppins;
        font-size: 13px;
        font-weight: bold;
        margin-top: 10px;
        margin-left: -25%;
    }
    .wr-text p{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 17px;
        text-align: justify;
        letter-spacing: 0.03em;
        color: #000000;
        margin-left: -12%;
    }

    .wr-image{
        float: right;
        width: 40%;
        padding: 35px;
    }
    .wr-image img{
        margin-left: 30%;
    }
    .wl-text{
        float: left;
        width: 60%;
        padding: 20px;
    }
    
    .wr-image h1{
        font-family: Poppins;
        font-size: 13px;
        font-weight: bold;
        margin-top: 10px;
        margin-left: 25%;
    }
    .wl-text p{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 17px;
        text-align: justify;
        letter-spacing: 0.03em;
        color: #000000;
        margin-left: 12%;
    }
    .Facility-eng{
        width: 50%;
        margin: 0 auto;
    }
    .Facility-eng h1{
        font-size: 18px;
        font-weight: bold;
        font-family: Poppins;
        margin-bottom: 10%;
    }
    .Facility-eng p{
        text-align: start;
        margin-top: 0px;
    }
    .left{
        list-style-type: none;
        position: relative;    /* It's needed for setting position to absolute in the next rule. */
        margin-left: 20% !important;
    }

    .left p::before{
        content: '■';
        position: absolute;
        left: -1.2em;          /* Adjust this value so that it appears where you want. */
        font-size: 1.1em;      /* Adjust this value so that it appears what size you want. */
    } 

    .right{
        list-style-type: none;
        position: relative;    /* It's needed for setting position to absolute in the next rule. */
        margin-left: 20% !important;
    }
    .right p::before{
        content: '■';
        position: absolute;
        left: -1.2em;          /* Adjust this value so that it appears where you want. */
        font-size: 1.1em;      /* Adjust this value so that it appears what size you want. */
    }

}

@media screen and (min-width: 768px) {
    .wr-text,.wl-text{
        margin-top: 3%;
    }
}

@media screen and (min-width: 900px) {
    .cards{
        display: grid;
        grid-template-columns: repeat(3,0.1fr);
        grid-gap: 20px;
        padding: 50px;
        justify-content: center;
        align-content: center;
    }
    .Workflow-process h1{
        font-size: 20px;
    }
    .row1{
        margin-left: 12%;
    }
    .Facility-eng{
        margin-bottom: -13%;
    }
}

@media screen and (min-width: 1200px) {
    .workflow-image{
        width: 60%;
    }
    .wr-text p,.wl-text p{
        font-size: 20px;
        line-height: 30px;
        margin-top: 5%;
        padding: 0px;
    }
    .card3{
        width: 80%;
        height: 400px;
    }
    .wr-image img,.wl-image img{
        width: 150px;
        margin-top: 20%;
    }
    .wl-image h1{
        font-family: Poppins;
        margin-left: -30%;
        font-size: 16px;
        margin-top: 25px;
    }

    .wr-image h1{
        font-family: Poppins;
        margin-left: 30%;
        font-size: 16px;
        margin-top: 25px;
    }
    .detailed-design p{
        margin-top: 1% !important;
    }
    .Facility-eng{
        margin-top: 7% !important;
        margin-bottom: -4%;
    }
    .right{
        margin-left: 15% !important;
    }
}

@media screen and (min-width: 1440px) {
    .card3{
        max-width: 1300px;
    }
    .row1{
        margin-left: 19%;
    }
    .wr-text p,.wl-text p{
        font-size: 20px;
        line-height: 30px;
        margin-top: 5%;
        padding: 30px;
    }
}

@media screen and (min-width: 1800px) {
    .Workflow-process h1{
        margin-top: 100px;
        font-size: 30px;
    }
    .row1{
        margin-left: 27%;
        margin-top: -7%;
    }
    .right{
        margin-left: 8% !important;
    }
}