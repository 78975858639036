table {
    font-family: Poppins;
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

@media screen and (max-width: 599px) {
    .Job1 h1, .Job2 h1{
        font-weight: bold;
        text-align: start;
        font-size: 25px;
        margin: 20px;
    }
    .Job-descriptions{
        width: 80%;
        margin: 0 auto;
    }
    .left-title{
        font-weight: bold;
        width: 180px;
    }
    .Job2{
        margin-top: 100px;
    }
}
  
@media screen and (min-width: 600px) {
    .Job1 h1, .Job2 h1{
        font-weight: bold;
        text-align: start;
        font-size: 25px;
        margin: 20px;
    }
    .Job-descriptions{
        width: 80%;
        margin: 0 auto;
    }
    .left-title{
        font-weight: bold;
        width: 180px;
    }
    .Job2{
        margin-top: 100px;
    }
    .job-dec-points{
        position: relative;
    }
    .job-dec-points p::before {
        content: '■';
        position: absolute;
        left: 0.2em;          /* Adjust this value so that it appears where you want. */
        font-size: 0.8em;      /* Adjust this value so that it appears what size you want. */
    }
    .job-dec-points p{
        margin-left: 0.5rem;
        text-align: justify;
    }
    .experience{
        text-align: justify;
    }
    
}

@media screen and (min-width: 1200px) {
    td{
        font-size: 18px;
    }
    .left-title{
        width: 250px;
    }
    .Job-descriptions{
        width: 80%;
        margin: 0 auto;
        max-width: 1400px;
    }
}