@media screen and (max-width: 599px) {
    .about-banner{
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .Aboutbanner,.key3{
        width: 70%;
    }
    .aboutus-header{
        font-family: Poppins;
        font-weight: bold;
        font-size: 20px;
    }
    .aboutus-text{
       width: 80%;
       margin: 0 auto;
    }
    .aboutus-text p{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        text-align: justify;
        letter-spacing: 0.02em;
        color: #000000;
    }

    .first-key,.second-key{
        width: 80%;
        margin: 0 auto;
        margin-top: 10%;
        height: 250px;
        margin-bottom: 5%;
    }
    .leftkey-text h1,.rightkey-text h1{
        font-weight: bold;
        font-family: Poppins;
        font-size: 18px;
        text-align: start;
        margin-top: 25%;
        line-height: 20px;
    }
    .leftkey-text p,.rightkey-text p{
        font-family: Poppins;
        font-size: 12px;
        text-align: start;
        margin-top: 2%;
    }
    .rightkey-image1,.leftkey-image1{
        width: 100%;
    }
    .leftkey-image1{
        margin-left: -20%;
    }
    .leftkey-text{
        float: left;
        width: 50%;
    }
    .rightkey-image{
        float: right;
        width: 50%;
        padding: 10px;
    }

    .rightkey-text{
        float: right;
        width: 50%;
    }
    .leftkey-image{
        width: 50%;
        float: left;
        padding: 10px;
    }

    .code-of-ethics{
        width: 80%;
        margin: 0 auto;
    }
    .code-of-ethics h1{
        font-size: 18px;
        font-family: Poppins;
        font-weight: bold;
    }
    .code-of-ethics p{
        font-family: Poppins;
        font-size: 15px;
        text-align: start;
    }
    .code-of-ethics {
        list-style-type: none;
        position: relative;    /* It's needed for setting position to absolute in the next rule. */
    }
    
    .code-of-ethics p::before {
        content: '■';
        position: absolute;
        left: -1em;          /* Adjust this value so that it appears where you want. */
        font-size: 1.1em;      /* Adjust this value so that it appears what size you want. */
    }
}


@media screen and (min-width: 600px) {
    .about-banner{
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .Aboutbanner,.key3{
        width: 70%;
    }
    .aboutus-header{
        font-family: Poppins;
        font-weight: bold;
        font-size: 20px;
    }
    .aboutus-text{
       width: 80%;
       margin: 0 auto;
    }
    .aboutus-text p{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        text-align: justify;
        letter-spacing: 0.02em;
        color: #000000;
    }

    .first-key{
        width: 80%;
        margin: 0 auto;
        margin-top: 10%;
        height: 250px;
        margin-bottom: 0%;
    }
    .second-key{
        width: 80%;
        margin: 0 auto;
        margin-top: 6%;
        height: 250px;
        margin-bottom: 6% !important;
    }
    .leftkey-text h1,.rightkey-text h1{
        font-weight: bold;
        font-family: Poppins;
        font-size: 18px;
        text-align: start;
        margin-top: 25%;
        line-height: 20px;
    }
    .leftkey-text p,.rightkey-text p{
        font-family: Poppins;
        font-size: 12px;
        margin-top: 2%;
        text-align: justify;
    }
    .rightkey-image1,.leftkey-image1{
        width: 85%;
    }
    .leftkey-image1{
        margin-left: -20%;
    }
    .leftkey-text{
        float: left;
        width: 50%;
    }
    .rightkey-image{
        float: right;
        width: 50%;
        padding: 10px;
    }

    .rightkey-text{
        float: right;
        width: 50%;
    }
    .leftkey-image{
        width: 50%;
        float: left;
        padding: 10px;
    }

    .code-of-ethics{
        width: 80%;
        margin: 0 auto;
    }
    .code-of-ethics h1{
        font-size: 18px;
        font-family: Poppins;
        font-weight: bold;
    }
    .code-of-ethics p{
        font-family: Poppins;
        font-size: 15px;
        text-align: start;

    }
    .code-of-ethics {
        list-style-type: none;
        position: relative;    /* It's needed for setting position to absolute in the next rule. */
    }
    
    .code-of-ethics p::before {
        content: '■';
        position: absolute;
        left: -1.2em;          /* Adjust this value so that it appears where you want. */
        font-size: 1.1em;      /* Adjust this value so that it appears what size you want. */
    }
   
}

@media screen and (min-width: 900px) {

    .leftkey-text,.rightkey-text{
        padding: 5px;
    }
    .leftkey-text p,.rightkey-text p{
        font-size: 18px;
    }
    .first-key{
        width: 80%;
        margin: 0 auto;
        margin-top: 10%;
        height: 350px;
        margin-bottom: 5%;
    }
    .second-key{
        width: 80%;
        margin: 0 auto;
        margin-top: 5%;
        height: 350px;
        margin-bottom: 5%;
    }
    .code-of-ethics{
        margin-bottom: -10%;
    }
}

@media screen and (min-width: 1200px) {
    .Aboutbanner,.key3{
        width: 50%;
    }
    .aboutus-header{
        font-size: 30px;
    }
    .aboutus-text p{
        font-size: 18px;
    }
    .leftkey-text h1,.rightkey-text h1{
        font-size: 30px;
    }
    .first-key,.second-key{
        height: 420px;
    }
    .code-of-ethics h1{
        font-size: 30px;
    }
    .code-of-ethics p{
        font-size: 18px;
    }
    .code-of-ethics{
        margin-bottom: -5%;
    }
}

@media screen and (min-width: 1440px) {
    .rightkey-image1,.leftkey-image1{
        width: 450px;
    }
    .first-key,.second-key{
        width: 80%;
        max-width: 1400px !important;
    }
    .code-of-ethics{
        width: 80%;
        max-width: 1400px !important;
    }
}