@media screen and (max-width: 599px) {
    .navbar-brand{
        margin-top: -9px;
    }
    nav{
        width: 100%;
        height: 160px;
        padding: 0px !important;
    }
    .navbar2{
        display: none;
    }
    .mj-logo{
        width: 250px;
        margin-top: -20px;
    }
    nav ul li{
        line-height: 45px;
        margin: 0 5px;
        text-align: start;
        margin-left: 5%;
        margin-top: 10%;
    }
    
    nav ul li a{
        color: white;
        font-size: 20px;
        text-align: start;
        font-family: Poppins;
        font-weight: bold;
    }

    .checkbtn{
        float: right;
        margin-right: 15px;
        cursor: pointer;
        margin-top: -20px;
    }
    .checkbtn:hover {
        color: black;
    }
    
    ul{
        position: fixed;
        width: 50%;
        height: 100vh;
        background: linear-gradient(180deg, #00D2FF 0%, #3A7BD5 100%);
        top: 0;
        right: -100%;
        text-align: center;
        transition: all .6s;
        z-index: 99999 !important;
        opacity: 1 !important;
    }
    #check{
        display: none;
    }
    nav ul li{
        display: block;
    }
    .main-nav-active {
        color: black;
    }
    
    #check:checked ~ ul{
        right: 0;
    }
    .hide-menu-btn{
        font-size: 40px;
        position: absolute;
        top: 10px;
        right: 7px;
        color: white;
    }

    .dropdown:hover>.dropdown-menu{
        display: block;
    }


    .dropdown-menu a{
        font-size: 18px;
        line-height: 20px;
    }
    .dropdown-menu{
        margin-left: -45px;
        margin-right: 200px !important;
    }

    .main-nav-active {
        color: black;
    }
}




@media screen and (min-width: 600px) {
    .navbar-brand{
        margin-top: -9px;
    }
    nav{
        width: 100%;
        height: 160px;
        padding: 0px !important;
    }
    .navbar2{
        display: none;
    }
    .mj-logo{
        width: 370px;
    }
    nav ul li{
        line-height: 70px;
        margin: 0 5px;
        text-align: start;
        margin-left: 5%;
        margin-top: 10%;
    }
    
    nav ul li a{
        color: white;
        font-size: 20px;
        text-align: start;
        font-family: Poppins;
        font-weight: bold;
    }

    .checkbtn{
        float: right;
        margin-right: 15px;
        cursor: pointer;
        margin-top: -20px;
    }
    .checkbtn:hover {
        color: black;
    }
    
    ul{
        position: fixed;
        width: 28%;
        height: 100vh;
        background: linear-gradient(180deg, #00D2FF 0%, #3A7BD5 100%);
        top: 0;
        right: -100%;
        text-align: center;
        transition: all .6s;
        z-index: 99999 !important;
    }
    #check{
        display: none;
    }
    nav ul li{
        display: block;
    }
    .main-nav-active {
        color: violet;
    }
    
    #check:checked ~ ul{
        right: 0;
    }
    .hide-menu-btn{
        font-size: 40px;
        position: absolute;
        top: 10px;
        right: 7px;
        color: white;
    }

    .dropdown:hover>.dropdown-menu{
        display: block;
    }


    .dropdown-menu a{
        font-size: 18px;
        line-height: 10px;
        margin-left: 0px;
    }
    .dropdown-menu{
        margin-right: 0px;
    }

  
 
}


@media screen and (min-width: 900px) {
    .mj-logo{
        margin-top: -80px;
    }
    nav{
        height: 180px;
        width: 100%;
    }
    .navbar2{
        display: block;
        height: 50px;
    }
    .navbar2 li{
        text-decoration: underline;
    }
    .navbar2 li a{
        font-size: 17px;
    }
    .top-links-hide{
        display: none !important;
    }
  
    nav ul li{
        display: inline-block !important;
        line-height: 70px;
        margin: 25px;
        text-align: none;
        margin-left: 0%;
        margin-top: 0%;
    }
    
    nav ul li a{
        color: black;
        font-size: 16px;
        text-align: center;
        font-family: Poppins;
        font-weight: bold;
    }

    
    .checkbtn{
        float: right;
        margin-right: 0px;
        cursor: pointer;
        display: none;
    }
    .checkbtn:hover {
        color: black;
    }
    
    ul{
        width: 100%;
        height: 0px;
        background: none;
        top: 0px;
        right:0px;
        text-align: end;
        transition: none;
        z-index: 0 !important;
        position: absolute;
    }
    #check{
        display: none;
    }
    nav ul li{
        display: block;
    }
    
    
    #check:checked ~ ul{
        right: 0;
    }

    .hide-menu-btn{
        font-size: 40px;
        position: absolute;
        top: 10px;
        right: 20px;
        color: white;
        display: none;
    }
    .download-icon{
        margin-top: -2px;
    }
    .associate{
        margin-top: 50px !important;
    }
    .masthead-dropdown{
        margin-left: -100%;
    }
    .dropdown-menu a{
        font-size: 18px;
        line-height: 30px;
        margin-left: 0px;
    }
}


@media screen and (min-width: 1200px) {
    nav{
        height: 240px;
        width: 100%;
    }
    nav ul li a{
        font-size: 17px;
    }
    .navbar2 li a{
        font-size: 15px;
    }
    .mj-logo{
        width: 550px;
        margin-top: -48px;
    }
    .dropdown-menu a{
        font-size: 18px;
        line-height: 30px;
        margin-left: 0px;
    }
    nav ul li a:hover{
        text-decoration: none;
    }
}

@media screen and (min-width: 1440px) {
    .mj-logo{
        width: 650px;
    }
    nav ul li a{
        font-size: 21px;
    }
}

@media screen and (min-width: 1800px) {
    .mj-logo{
        width: 650px;
    }
    nav ul li a{
        font-size: 22px;
        margin: 18px;
    }
}