@media screen and (max-width: 599px) {
    .Job-form{
        width: 70%;
        margin: 0 auto;

    }
    
    .Job-form p{
        text-align: start;
        width: 240px;
        font-size: 15px;
    }
    
    .Job-form input{
        width: 280px;
        height: 30px;
        padding: 10px;
    }
    .Job-form input[type="text"], input[type="email"], input[type="number"], input[type="date"] {
        height: 40px;
        color: black;
        border: 3px solid #85C1EA;
        box-sizing: border-box;
        border-radius: 50px;
    }
    .job-form-btn{
        width: 8rem;
        border: 2px solid red;
        font-size: 20px;
    }
    .Job-form textarea{
        width: 280px;
        border: 3px solid #85C1EA;
        box-sizing: border-box;

        padding: 10px;
    }
}
@media screen and (max-width: 375px) {
    .Job-form{
        width: 75%;
    }
}

@media screen and (min-width: 600px) {
    .Job-form{
        width: 50%;
        margin: 0 auto;
    }
    
    .Job-form p{
        text-align: start;
        width: 240px;
        font-size: 20px;
    }
    
    .Job-form input{
        width: 280px;
        height: 50px;
        padding: 10px;
    }
    .Job-form input[type="text"], input[type="email"], input[type="number"], input[type="date"] {
        height: 55px;
        color: black;
        border: 3px solid #85C1EA;
        box-sizing: border-box;
        border-radius: 50px;
    }
    .job-form-btn{
        width: 8rem;
        border: 2px solid red;
        font-size: 20px;
    }
}


  @media screen and (min-width: 900px) {
    .Job-form{
        width: 400px;
        margin: 0 auto;
    }
    .Job-form input{
        width: 380px;
        padding: 10px;
    }
    .Job-form textarea{
        width: 380px;
        border: 3px solid #85C1EA;
        box-sizing: border-box;

        padding: 10px;
    }
  }