@media screen and (max-width: 599px) {
    .Gallery-content h1{
        font-family: Poppins;
        font-size: 20px;
        font-weight: bold;
        margin-top: 5%;
    }
    .photos-section{
        width: 70%;
        padding: 1rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 100px rgba(72, 106, 71, 0.5);
        border-radius: 50px;
        margin: 0 auto;
        margin-top: 5%; 
    }
    .gallery img{
        width: 150px;
        padding: 10px;
    }

#lightbox{
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, .8);
    display: none;
}

#lightbox img{
   border: 2px solid white;
   max-height: 50%;
   width: 50%;
}

#lightbox.active{
    display: flex;
    justify-content: center;
    align-items: center;
}
.gallery img{
    cursor: pointer;
}
}


@media screen and (min-width: 600px) {
    .Gallery-content h1{
        font-family: Poppins;
        font-size: 20px;
        font-weight: bold;
        margin-top: 5%;
    }
    .photos-section{
        width: 70%;
        padding: 1rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 100px rgba(72, 106, 71, 0.5);
        border-radius: 50px;
        margin: 0 auto;
        margin-top: 5%; 
    }
    .gallery img{
        width: 150px;
        padding: 10px;
    }

#lightbox{
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, .8);
    display: none;
}

#lightbox img{
   border: 2px solid white;
   max-height: 100%;
   width: auto;
}

#lightbox.active{
    display: flex;
    justify-content: center;
    align-items: center;
}
.gallery img{
    cursor: pointer;
}
     
}  

@media screen and (min-width: 900px) {
    .photos-section{
        width: 80%;
        padding: 2rem;
    }
    .gallery img{
        width: 230px;
        padding: 10px;
    }
}

@media screen and (min-width: 1200px) {
    .Gallery-content h1{
        font-size: 30px;
    }
    .gallery img{
        width: 250px;
        padding: 10px;
    }
}

@media screen and (min-width: 1440px) {
    .gallery img{
        width: 350px;
        padding: 10px;
    }
}



