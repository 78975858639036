@media screen and (max-width: 599px) {
  .Contact-details{
    margin-top: 10%;
}
.contact-form input{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .contact-form input[type="text"], input[type="email"], input[type="number"] {
    height: 55px;
    color: black;
    border: 3px solid #85C1EA;
    box-sizing: border-box;
    border-radius: 50px;
  }
  .contact-dropdown {
    width: 100%;
    height: 55px;
    color: black;
    margin-bottom: 20px;
    border: 3px solid #85C1EA;
    box-sizing: border-box;
    border-radius: 50px;
    background-color: white !important;
  }

  .submit-btn{
    background: #23713D;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    float: left;
    color: white;
    width: 10rem;
    font-size: 20px;
    font-family: Poppins;
  }

  .contact-div{
    width: 80%;
    margin: 0 auto;
    margin-top: 5%;
  }

input::placeholder{
    font-family: Poppins;
    text-align: initial;
}
.contact-vector{
    width: 100%;
    margin-top: 50px;
}
}


@media screen and (min-width: 600px) {
    .Contact-details{
        margin-top: 10%;
    }
    .contact-form input{
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .contact-form input[type="text"], input[type="email"], input[type="number"] {
        height: 55px;
        color: black;
        border: 3px solid #85C1EA;
        box-sizing: border-box;
        border-radius: 50px;
      }
      .contact-dropdown {
        width: 100%;
        height: 55px;
        color: black;
        margin-bottom: 20px;
        border: 3px solid #85C1EA;
        box-sizing: border-box;
        border-radius: 50px;
        background-color: white !important;
      }
    
      .submit-btn{
        background: #23713D;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 50px;
        float: left;
        color: white;
        width: 10rem;
        font-size: 20px;
        font-family: Poppins;
      }
    
      .contact-div{
        width: 80%;
        margin: 0 auto;
        margin-top: 5%;
      }
    
    input::placeholder{
        font-family: Poppins;
        text-align: initial;
    }
    .contact-vector{
        width: 100%;
        margin-top: 50px;
    }
    .msg{
      display: none;
    }
    .msgAppear{
      display: block;
    }
}

@media screen and (min-width: 1200px) {
    .contact-vector{
        margin-top: -10%;
        margin-left: 10%;
    }
}


@media screen and (min-width: 1800px) {
    .contact-vector{
        margin-top: -18%;
    }
}